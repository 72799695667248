@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.--cards-animation {
    opacity: 0;
}

.--el-animation {
    opacity: 0;

    &.--animated {
        opacity: 1;
    }
}