@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.contact-info {
    &__form {
        grid-column: 1 / -1;
        border-top: 1px solid;
        padding-top: f.px2rem(116);
        padding-bottom: f.px2rem(64);
        row-gap: 0;

        .form-input-container {
            margin-bottom: 1rem;

            .--border {
                &.just-border {
                    border: none;
                }
            }
        }

        .btn {
            margin-top: f.px2rem(25);
            background-color: v.$creamy-white;
        }
    }

    &__links {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
        gap: f.px2rem(16);

        a {
            transition: all 0.3s ease-in-out;

            &:hover {
                color: v.$dark-bluish;
            }
        }
    }

    @include m.screen(600px) {
        &__form {
            .contact-form {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: f.px2rem(16);

                .input-name {
                    grid-column: 1 / 2;
                }

                .input-last_name {
                    grid-column: 2 / 3;
                }

                .input-email {
                    grid-column: 1 / 3;
                }

                .--textarea {
                    grid-column: 1 / 3;
                }

                .btn {
                    grid-column: 1 / 3;
                }
            }
        }
    }

    @include m.screen("md") {
        &__container {
            .--border {
                &.just-border {
                    border-top: 1px solid;
                    grid-column: 1 / -1;
                    grid-row: 1;
                }
            }
        }

        &__form {
            grid-column: 7 / span 6;
            grid-row: 1;
            border: none;

            &.--border {
                border: none;
            }

            .btn {
                grid-column: 2;
                justify-self: flex-end;
            }
        }

        &__links {
            grid-column: 1 / span 6;
            grid-row: 1;
            padding-top: f.px2rem(116);
        }
    }

    @include m.screen("lg") {
        &__form {
            .form-input-container {
                margin-bottom: 1.5rem;
            }
        }
    }

    @include m.screen("xl") {
        &__form {
            padding-top: f.px2rem(256);

            .form-input-container {
                margin-bottom: 2rem;
            }
        }

        &__links {
            padding-top: f.px2rem(256);
        }
    }
}