@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.text-frame-fullwidth {
    background-size: cover;
    background-position: center;

    &.height-full {
        display: flex;
        align-items: center;
    }
    
    &__container {
        gap: f.px2rem(32);
        
        .text-tag {
            grid-column: 1 / -1;
        }

        .text-statement {
            grid-column: 1 / -1;
        }

        .paragraph {
            grid-column: 1 / -1;
            margin-top: f.px2rem(-20);
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(16);
        grid-column: 1 / -1;
    }

    @include m.screen("sm") {
        &__container {
            gap: f.px2rem(48);
        }

        &.align-center {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 2 / span 10;
                }
        
                .text-statement {
                    grid-column: 2 / span 10;
                }
        
                .paragraph {
                    grid-column: 3 / span 8;
                }
            }
        }

        &.align-left {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 1 / span 11;
                }
        
                .text-statement {
                    grid-column: 1 / span 11;
                }
        
                .paragraph {
                    grid-column: 1 / span 8;
                }
            }

            .text-frame-fullwidth__ctas {
                width: max-content;
                grid-column: 1 / span 11;
            }
            
        }
        
        &__ctas {
            @include m.flex-center(row);
            gap: f.px2rem(16);
        }

        .text-tag {
            margin-bottom: f.px2rem(32);
        }

        .paragraph {
            margin-top: f.px2rem(-24);
        }
    }

    @include m.screen("md") {
        &.align-center {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 3 / span 8;
                }
        
                .text-statement {
                    grid-column: 3 / span 8;
                }

                .paragraph {
                    grid-column: 4 / span 6;
                }
            }
        }

        &.align-left {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 1 / span 8;
                }
        
                .text-statement {
                    grid-column: 1 / span 8;
                }

                .paragraph {
                    grid-column: 1 / span 6;
                }
            }
        }
    }

    @include m.screen("xl") {
        &__container {
            gap: f.px2rem(96);
        }

        &.align-left {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 1 / span 10;
                }
        
                .text-statement {
                    grid-column: 1 / span 10;
                }

                .paragraph {
                    grid-column: 1 / span 6;
                }
            }
        }

        &.align-center {
            .text-frame-fullwidth__container {
                .text-tag {
                    grid-column: 2 / span 10;
                }
        
                .text-statement {
                    grid-column: 2 / span 10;
                }

                .paragraph {
                    grid-column: 4 / span 6;
                }
            }
        }

        .text-tag {
            margin-bottom: f.px2rem(32);
        }

        .text-statement {
            margin-bottom: f.px2rem(-48);
        }
    }
}
