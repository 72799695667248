@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.cookie-bar {
    right: f.px2rem(32);
    padding: 1rem 1rem 0 1rem;
    top: 100vh;
    left: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    width: 100%;

    .container {
        background-color: rgba(36, 50, 63, 0.70);
        border-radius: f.px2rem(16);
        backdrop-filter: blur(20px);
        color: v.$white;
        display: flex;
        flex-direction: column;
        gap: f.px2rem(24);
        padding: f.px2rem(24) f.px2rem(24) f.px2rem(45) f.px2rem(24);
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }
    
    &.sticky {
        top: 90%;
        transform: translateY(-100%);
        position: sticky;

        .container {
            opacity: 1;
        }
    }

    &.hidden {
        display: none;
    }
    
    .action-container {
        position: relative;

        .form {
            position: absolute;
            top: 0;

            .form__list {
                height: 0;
                visibility: hidden;
            }

            .btns-container {
                display: flex;
                gap: f.px2rem(24);

                .link {
                    position: relative;
                }

                input {
                    visibility: hidden;
                    height: f.px2rem(24);
                    width: 100%;
                    position: absolute;
                }

                svg {
                    path {
                        fill: v.$white;
                    }
                }
            }
        }
    }

    @include m.screen ("sm") {
        max-width: f.px2rem(287);
        right: f.px2rem(32);
        left: auto;
        position: fixed;
        bottom: f.px2rem(88);
        top: auto;
        padding-bottom: f.px2rem(48);
        padding-left: 0;
        padding-right: 0;
        
        .container {
            opacity: 1;
        }

        &.sticky {
            top: auto;
            transform: none;
            position: fixed;
        }
    }

    @include m.screen("xl") {
        max-width: f.px2rem(584);

        .container {
            padding: f.px2rem(48) f.px2rem(48) f.px2rem(96) f.px2rem(48);
        }
    }
}