@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.text-with-media-image {

    &.height-full {
        display: block;
        margin-top: auto;
        margin-bottom: auto;

        .text-with-media-image__container, .text-with-media-image__image {
            img {
                box-shadow: none;
            }
        }
    }

    &__content {
        grid-column: 1 / -1;
        padding-top: f.px2rem(64);
        padding-bottom: f.px2rem(64);
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 1rem;

        .text-tag {
            margin-bottom: f.px2rem(32);
        }

        .text-statement {
            margin-bottom: f.px2rem(12);
        }

        .paragraph {
            margin-bottom: f.px2rem(32);
            
            p {
                margin-bottom: f.px2rem(14);

                @include m.screen("sm") {
                    margin-bottom: f.px2rem(16);
                }
            
                @include m.screen("xl") {
                    margin-bottom: f.px2rem(32);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(12);
    }

    &__image {
        grid-column: 1 / -1;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @include m.screen(600px) {
        &__content {
            padding-top: f.px2rem(128);
            padding-bottom: f.px2rem(128);
    
            .text-tag {
                margin-bottom: f.px2rem(64);
            }
    
            .text-statement {
                margin-bottom: f.px2rem(24);
            }
    
            .paragraph {
                margin-bottom: f.px2rem(64);
            }
        }

        &__ctas {
            gap: f.px2rem(24);
        }
    }

    @include m.screen("sm") {
        &__image {
            margin-left: -1.5rem;
        }

        &__content {
            padding-right: 1.5rem;
        }
    }

    @include m.screen("md") {
        &__content {
            padding-top: 0;
            padding-bottom: 0;
            grid-row: 1;
        }

        &__image {
            grid-row: 1;
            width: auto;
            margin-left: auto;
        }
        
        &.height-full {
            .text-with-media-image__container {
                padding-top: 0;
                padding-bottom: 0;
                grid-row: 1;
            }

            .--grid {
                min-height: 100dvh;
            }
        }

        &.height-auto {
            .text-with-media-image__image {
                img {
                    border-radius: f.px2rem(16);
                }
            }
        }

        &.text-left {
            &.height-full {
                .--grid {
                    padding-right: 0;
                }
            }

            .text-with-media-image__content {
                grid-column: 1 / span 6;
                padding-right: f.px2rem(56);

                span {
                    border-left: 1px solid;
                    display: block;
                    padding-left: f.px2rem(48);
                }
            }
    
            .text-with-media-image__image {
                grid-column: 7 / span 6;
            }

            .text-with-media-image__ctas {
                padding-left: f.px2rem(32);
            }
        }

        &.text-right {
            &.height-full {
                .--grid {
                    padding-left: 0;
                }
            }

            .text-with-media-image__content {
                grid-column: 7 / span 6;
                padding-left: f.px2rem(56);

                span {
                    border-right: 1px solid;
                    display: block;
                    padding-right: f.px2rem(48);
                }
            }

            .text-with-media-image__image {
                grid-column: 1 / span 6;
            }
        }

        &__ctas {
            align-items: flex-start;
        }
    }

    @include m.screen("xl") {
        &__content {
            .text-tag {
                margin-bottom: f.px2rem(128);
            }
    
            .text-statement {
                margin-bottom: f.px2rem(48);
            }
    
            .paragraph {
                margin-bottom: f.px2rem(96);
            }
        }

        &.text-left {
            .text-with-media-image__content {
                padding-right: f.px2rem(112);

                span {
                    padding-left: f.px2rem(96);
                }
            }

            .text-with-media-image__ctas {
                padding-left: f.px2rem(64);
            }
        }

        &.text-right {
            .text-with-media-image__content {
                padding-left: f.px2rem(112);

                span {
                    padding-right: f.px2rem(96);
                }
            }
        }

        &__ctas {
            gap: f.px2rem(48);
        }
    }
}