// --------------------------------------------------
// Breakpoints
// Based on Tailwind CSS
// https://tailwindcss.com/docs/breakpoints
// --------------------------------------------------

$sm: 745px;
$md: 1024px;
$lg: 1280px;
$xl: 2560px;

// --------------------------------------------------
// Colors
// --------------------------------------------------
// PRIMARY
$dark-bluish: #24323F;
$bluish-teal: #434F58;
$brownish: #746862;
$creamy-white: #F6F0ED;
$white: #F9F7F6;
$orange: #E99A4D;

// TINTS
$dark-bluish-72: rgba($dark-bluish, 0.72);
$dark-bluish-tint-40: rgba($dark-bluish, 0.4);
$dark-bluish-tint-20: rgba($dark-bluish, 0.2);
$white-tint-40: rgba($white, 0.4);

// --------------------------------------------------
// Border radius
// --------------------------------------------------

$border-btns: 6.25rem;
$border-radius-sm: 0.125rem;
$border-radius: 0.25rem;
$border-radius-md: 0.375rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 0.75rem;
$border-radius-xxl: 1rem;

// --------------------------------------------------
// Width
// --------------------------------------------------
$max_width_wrapper: $xl;

// --------------------------------------------------
// Animations
// --------------------------------------------------
$timming: 0.2s;
