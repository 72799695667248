@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.pricing-list {
    &__container {
        &.--grid {
            row-gap: f.px2rem(32);
        }
    }

    &__border {
        border-top: 1px solid;
        grid-column: 1 / -1;
    }

    &__content {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: f.px2rem(16);

        .toggle {
            position: relative;
            width: 100%;
            height: 60px;
            border-radius: 30px;
            overflow: hidden;
            padding: 9.71px 6px;
        }
        
        .slider {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 9.71px;
            width: calc(50% - 9.71px);
            height: calc(100% - 12px);
            background-color: v.$bluish-teal;
            transition: left 0.3s ease;
            border-radius: 30px;
            z-index: 0;
        }
        
        .toggle label {
            position: absolute;
            width: calc(50% - 9.71px);
            height: 48px;
            text-align: center;
            line-height: 30px; 
            cursor: pointer;
            transition: all 0.3s ease;
            background-color: transparent;
            padding: f.px2rem(12) f.px2rem(16);
            border-radius: v.$border-btns;
            @include m.font-size(16, 400, 1.5, -1);

            @include m.screen("sm") {
                @include m.font-size(20, 400, 1.5, -1);
                padding: f.px2rem(16) f.px2rem(24);
            }
        }

        .input-left, .input-right {
            display: none;
        }
        
        .label-left {
            z-index: 2;
            top: 50%;
            left: 9.7px;
            transform: translateY(-50%);

            &.checked {
                color: white;
            }
        }
        
        .label-right {
            z-index: 1;
            top: 50%;
            right: 9.7px;
            transform: translateY(-50%);
            
            &.checked {
                color: white;
            }
        }

        .input-left:checked ~ .slider {
            left: 9.71px;
        }
        
        .input-right:checked ~ .slider {
            left: 50%;
        }
    }

    &__left, &__right {
        display: none;
        grid-column: 1 / -1;

        &.active {
            display: flex;
            flex-direction: column;
            gap: f.px2rem(32);
        }
    }

    &__box {
        padding: f.px2rem(32);
        border-radius: f.px2rem(24);

        .text-tag {
            margin-bottom: f.px2rem(32);
            margin-top: f.px2rem(4);
        }

        .--border {
            padding-top: f.px2rem(8);
            padding-bottom: f.px2rem(8);
            border-bottom: 1px solid;
        }

        &__features {
            display: flex;
            flex-direction: column;

            .feature {
                display: flex;
                padding-top: f.px2rem(8);
                padding-bottom: f.px2rem(8);
                gap: f.px2rem(16);

                svg {
                    width: f.px2rem(20);
                    height: f.px2rem(20);
                }

                .paragraph {
                    margin-left: f.px2rem(36);
                }
            }
        }

        .btn {
            margin-top: f.px2rem(32);
        }
    }

    @include m.screen("sm") {
        &__container {
            &.--grid {
                row-gap: f.px2rem(64);
            }
        }

        &__content {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .toggle {
                min-width: 212px;
                width: max-content;

                label {
                    padding: 0.75rem 1rem;
                }
            }
        }

        &__box {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            padding-top: f.px2rem(48);

            .--border {
                padding-bottom: f.px2rem(16);
            }

            &__features {
                .feature {
                    padding-top: f.px2rem(16);
                    padding-bottom: 0;
                }
            }


            .price {
                padding-right: f.px2rem(48);
                grid-column: 1;
            }

            .items {
                grid-column: 2;
                grid-row: 1 / span 2;
            }

            .btn {
                height: max-content;
                align-self: end;
                width: calc(100% - 48px);
                margin-right: f.px2rem(48);
                margin-top: 0;
                grid-column: 1;
                grid-row: 2;
            }
        }
    }

    @include m.screen("md") {
        &__content {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        &__left, &__right {
            &.active {
                grid-column: 1 / -1;
                width: 100%;
                @include m.grid();
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        &__box {
            display: flex;
            flex-direction: column;
            grid-column: span 4;
            padding-top: f.px2rem(32);

            .price {
                padding-right: 0;

                .title {
                    margin-bottom: f.px2rem(64);
                }

                .text-tag {
                    margin-bottom: f.px2rem(64);
                }
            }

            .items {
                padding-bottom: f.px2rem(64);

                .--border {
                    padding-top: f.px2rem(16);
                }
            }

            .btn {
                margin-top: auto;
                margin-right: 0;
                width: 100%;
            }
        }
    }

    @include m.screen("xl") {
        &__container {
            &.--grid {
                row-gap: f.px2rem(128);
            }
        }

        &__content {
            gap: f.px2rem(64);
        }

        &__box {
            padding: f.px2rem(64);

            .price {
                .title {
                    margin-bottom: f.px2rem(128);
                }

                .text-tag {
                    margin-bottom: f.px2rem(128);
                }
            }

            &__features {    
                .feature {
                    padding-top: f.px2rem(32);
                    gap: f.px2rem(32);

                    svg {
                        width: f.px2rem(40);
                        height: f.px2rem(40);
                    }

                    .paragraph {
                        margin-left: f.px2rem(72);
                    }
                }
            }
    
            
            .items {
                .--border {
                    padding-top: f.px2rem(32);
                    padding-bottom: f.px2rem(32);
                }
            }
        }
    }
}