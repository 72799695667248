@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.feature-with-image {
    &__container {
        &.--grid {
            grid-row-gap: f.px2rem(32);
        }
    }

    &__content {
        grid-column: 1 / -1;
        border-radius: f.px2rem(16);
        display: flex;
        flex-direction: column;
    }

    &__image {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: f.px2rem(16);
        }
    }

    &__text {
        padding-top: f.px2rem(22);
        display: flex;
        flex-direction: column;
        gap: f.px2rem(8);
    }

    @include m.screen("sm") {
        &__container {
            &.--grid {
                grid-row-gap: f.px2rem(80);
            }
        }

        &__content {
            grid-column: span 6;
        }

        &__text {
            padding-top: f.px2rem(30);
            padding-right: f.px2rem(24);
            gap: f.px2rem(16);

            .link {
                margin-top: f.px2rem(32);
            }
        }
    }

    @include m.screen("lg") {
        &__content {
            grid-column: span 3;
        }
    }

    @include m.screen("xl") {
        &__text {
            padding-top: f.px2rem(60);
            padding-right: f.px2rem(40);
            gap: f.px2rem(32);

            .link {
                margin-top: f.px2rem(64);
            }
        }
    }
}