@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.text-frame-wrapped {
    // padding-right: 0;
    // padding-left: 0;
    max-width: none;
    
    &__container {
        background-size: cover;
        background-position: center;
        gap: f.px2rem(48);
        padding-top: f.px2rem(128);
        padding-bottom: f.px2rem(128);
        border-radius: 1rem;
    }

    &__text, &__ctas {
        grid-column: 1 / -1;
    }

    &__text {
        .text-tag {
            margin-bottom: f.px2rem(64);
        }

        .text-statement {
            margin-bottom: f.px2rem(24);
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(16);

        .btn {
            width: max-content;
        }
    }

    &.outside-color-white {
        background-color: v.$white;
    }

    &.outside-color-teal {
        background-color: v.$bluish-teal;
    }

    &.outside-color-creamy {
        background-color: v.$creamy-white;
    }

    @include m.screen("sm") {
        padding-right: f.px2rem(16);
        padding-left: f.px2rem(16);

        &__container {
            border-radius: f.px2rem(24);
        }

        &__text, &__ctas {
                grid-column: 2 / span 10;
        }
        
        &__ctas {
            gap: f.px2rem(24);
        }
    }

    @include m.screen("md") {
        padding-right: f.px2rem(30);
        padding-left: f.px2rem(30);

        &__text, &__ctas {
            grid-column: 3 / span 8;
        }

        &__ctas {
            @include m.flex-center(row);
        }
    }

    @include m.screen("xl") {
        &__container {
            padding-top: f.px2rem(256);
            padding-bottom: f.px2rem(256);
            gap: f.px2rem(96);
        }

        &__text {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-column-gap: f.px2rem(32);

            .text-tag {
                margin-bottom: f.px2rem(128);
                grid-column: 1 / -1;
            }
    
            .text-statement {
                margin-bottom: f.px2rem(48);
                grid-column: 1 / -1;
            }

            .paragraph {
                grid-column: 3 / span 4;
            }
        }
    }
}
