@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.card-with-icon {
    &__container {
        gap: f.px2rem(16);
    }

    &__content {
        grid-column: 1 / -1;
        border-radius: f.px2rem(16);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__image {
        padding-top: f.px2rem(32);
        padding-bottom: f.px2rem(32);
        padding-left: f.px2rem(24);
        padding-right: f.px2rem(24);
        display: flex;
        height: f.px2rem(140);

        img {
            width: f.px2rem(48);
            height: f.px2rem(48);
        }
    }

    &__text {
        padding-top: f.px2rem(16);
        padding-bottom: f.px2rem(32);
        padding-left: f.px2rem(32);
        padding-right: f.px2rem(32);

        .text-p-title {
            margin-bottom: f.px2rem(8);
        }

        .btn {
            margin-top: f.px2rem(32);
        }
    }

    @include m.screen("sm") {
        &__content {
            flex-direction: row;
            @include m.grid();
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0;
            padding-bottom: 0;
        }

        &__image {
            grid-column: 1 / span 4;
            padding-top: f.px2rem(48);
        }

        &__text {
            padding-top: f.px2rem(48);
            padding-left: f.px2rem(24);
            padding-right: f.px2rem(24);
            padding-bottom: f.px2rem(48);
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-column: 5 / -1;

            .btn {
                margin-top: f.px2rem(48);
            }

            .text-p-title {
                margin-bottom: f.px2rem(16);
            }
        }
    }

    @include m.screen("md") {
        &__content {
            display: flex;
            flex-direction: column;
            grid-column: span 4;
            padding-top: 0;
            padding-bottom: 0;
        }

        &__image {
            padding-top: f.px2rem(32);
        }

        &__text {
            width: 100%;
            padding-top: f.px2rem(24);
            padding-bottom: f.px2rem(24);
            height: 100%;
            justify-content: flex-start;

            .paragraph {
                margin-bottom: f.px2rem(48);
            }

            .btn {
                margin-top: auto;
            }
        }
    }

    @include m.screen("md") {
        &__image {
            height: f.px2rem(160);
        }
    }

    @include m.screen("xl") {
        &__image {
            padding-top: f.px2rem(64);
            padding-bottom: f.px2rem(64);
            padding-left: f.px2rem(48);
            padding-right: f.px2rem(48);
            height: f.px2rem(320);

            img {
                width: f.px2rem(96);
                height: f.px2rem(96);
            }
        }

        &__text {
            padding-top: f.px2rem(48);
            padding-left: f.px2rem(48);
            padding-right: f.px2rem(48);
            padding-bottom: f.px2rem(48);

            .text-p-title {
                margin-bottom: f.px2rem(32);
            }
        }
    }
}