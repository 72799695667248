@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.long-text-with-chapters {
    
    &__boxes {
        grid-column: 1 / -1;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: f.px2rem(32);
        padding-top: f.px2rem(32);
        padding-bottom: f.px2rem(64);
        border-top: 1px solid;
        transition: all 0.3s ease-in-out;
        opacity: 0.4;

        &.active {
            opacity: 1;
        }

        .paragraph {
            p {
                margin-bottom: f.px2rem(14);

                @include m.screen("sm") {
                    margin-bottom: f.px2rem(16);
                }
            
                @include m.screen("xl") {
                    margin-bottom: f.px2rem(32);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .form__container {
            form {
                .form-input-container {
                    margin-bottom: 1rem;
                }

                .icon-container {
                    svg {
                        height: f.px2rem(16);
                        width: f.px2rem(16);
                    }
                }
                .btn {
                    margin-top: f.px2rem(56);
                    background-color: v.$creamy-white;
                    width: 100%;
                }

                .input-cv {
                    padding-top: 1.1rem;
                    
                    .form-control {
                        position: relative;
                        display: inline-block;

                        input {
                            width: 100%;
                            height: 100%;
                        }

                        span {
                            position: absolute;
                            top: f.px2rem(32);
                            width: 100%;
                            text-align: center;
                            background-color: v.$white;
                            color: v.$dark-bluish;
                            display: flex;
                            flex-direction: column;
                            padding-top: f.px2rem(8);
                            padding-bottom: f.px2rem(12);
                            border-bottom: 1px solid;
                            cursor: pointer;
                            
                            .detail {
                                @include m.font-size(12, 500, 1.5);
                            }
                        }

                        input[type="file"] {
                            opacity: 0;
                            display: inline-block;
                        }

                        .text-error {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                .div-btn {
                    padding-top: f.px2rem(28);
                }
            }
        }
    }

    &__chapters {
        display: none;
        padding-bottom: f.px2rem(64);
        height: max-content;

        &.visible {
            position: sticky;
            top: f.px2rem(86);
        }
    }

    @include m.screen("sm") {
        &__content {
            padding-top: f.px2rem(64);

            .form__container {
                form {
                    .group {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 16px;
                    }

                    .icon-container {
                        svg {
                            height: f.px2rem(20);
                            width: f.px2rem(20);
                        }
                    }

                    .input-cv {
                        .form-control {
                            span {
                                gap: f.px2rem(4);

                                .detail {
                                    @include m.font-size(14, 500, 1.5);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include m.screen("md") {
        .--border {
            &.top {
                border-top: 1px solid;
                width: 100%;
                grid-column: 1 / -1;
            }
        }

        &__chapters {
            display: flex;
            flex-direction: column;
            gap: f.px2rem(16);
            grid-column: 1 / span 6;
            padding-top: f.px2rem(64);

            a {
                filter: opacity(40%);
                width: max-content;
                transition: all 0.3s ease-in-out;

                &.active {
                    filter: opacity(100%);
                }
            }
        }

        &__boxes {
            grid-column: 7 / -1;
        }
        
        &__content {
            &.border-none {
                border-top: none;
            }
        }
    }

    @include m.screen("lg") {
        .form__container {
            form {
                .form-input-container {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    @include m.screen("xl") {
        .--border {
            &.top {
                top: f.px2rem(100);
            }
        }

        &__content {
            padding-top: f.px2rem(128);
            padding-bottom: f.px2rem(128);
            gap: f.px2rem(64);

            .form__container {
                form {

                    .form-input-container {
                        margin-bottom: 2rem;
                    }

                    .input-cv {
                        height: 282px;
                        padding-top: 77px;

                        .form-control {

                            span {
                                bottom: 24px;
                                font-size: 24px;
                                gap: f.px2rem(8);
                                padding-top: f.px2rem(16);
                                padding-bottom: f.px2rem(24);

                                .detail {
                                    @include m.font-size(40, 400, 1.5, -1);
                                }
                            }
                        }

                        label {
                            margin-top: -32px;
                        }
                    }

                    .group {
                        gap: 32px;
                    }

                    .btn {
                        margin-top: 80px;
                    }
                }
            }
        }

        &__chapters {
            padding-top: f.px2rem(128);
            gap: f.px2rem(64);
            padding-bottom: f.px2rem(128);
            top: f.px2rem(100);
        }
    }
}