// Import this in all scss files where you need them
@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.t-creamy-white {
    background-color: v.$creamy-white;

    // Styles for tags and titles
    .tag-and-title {
        color: v.$dark-bluish;
        border-color: v.$dark-bluish !important;
    }

    // Styles for paragraphs
    .paragraph {
        color: v.$dark-bluish-72;
    }

    // Styles for borders
    .--border {
        color: v.$dark-bluish;
        border-color:  v.$dark-bluish-tint-40 !important;
    }

    // Styles for shadows
    &.shadow-outer {
        @include m.outer-shadow-light;
    }

    .shadow-outer {
        @include m.outer-shadow-light;
    }

    &.shadow-inner {
        @include m.inner-shadow-light;
    }

    .shadow-inner {
        @include m.inner-shadow-light;
    }

    // Styles for btns
    .btn {
        // border: 1px v.$white solid;
        color: v.$dark-bluish;
        @include m.outer-shadow-light;

        @include m.screen("md") {
            &:hover {
                @include m.inner-shadow-light;
            }
        }
        
        &.outlined {
            &:hover {
                background-color: v.$dark-bluish;
                border-color: v.$dark-bluish !important;
                color: v.$white;
            }
        }
    }

    .link {
        a {
            color: v.$dark-bluish;

            svg {
                path {
                    fill: v.$dark-bluish;
                }
            }

            &::before {
                background-color: v.$dark-bluish;
            }
        }
    }

    // Styles for form
    .form {
        &-input-container {
            background-color: v.$creamy-white;
        }

        &-input {
            border-color: v.$dark-bluish-tint-40 !important;

            &:hover {
                background-color: v.$white;
            }
        }
    }

    .--color-tint {
        color: v.$dark-bluish-tint-40;
    }
}

.t-bluish-teal {
    background-color: v.$bluish-teal;

    // Styles for tags and titles
    .tag-and-title {
        color: v.$creamy-white;
        border-color: v.$creamy-white !important;
    }

    // Styles for paragraphs
    .paragraph {
        color: v.$creamy-white;
    }

    // Styles for borders
    .--border {
        color: v.$creamy-white;
        border-color: v.$white-tint-40 !important;
    }

    // Styles for shadows
    &.shadow-outer {
        @include m.outer-shadow-dark();
    }

    .shadow-outer {
        @include m.outer-shadow-dark();
    }

    &.shadow-inner {
        @include m.inner-shadow-dark();
    }

    .shadow-inner {
        @include m.inner-shadow-dark();
    }

    // Styles for btns
    .btn {
        color: v.$creamy-white;
        // border: 1px v.$dark-bluish-tint-40 solid;
        background-color: v.$bluish-teal;
        @include m.outer-shadow-dark();

        @include m.screen("md") {
            &:hover {
                @include m.inner-shadow-dark();
            }
        }

        &.outlined {
            &:hover {
                background-color: v.$creamy-white;
                color: v.$bluish-teal;
                border-color: v.$creamy-white !important;
            }
        }
    }

    .link {
        a {
            color: v.$creamy-white;

            svg {
                path {
                    fill: v.$creamy-white;
                }
            }

            &::before {
                background-color: v.$creamy-white;
            }
        }
    }

    .form {
        &-input-container {
            background-color: v.$bluish-teal;
        }

        &-input {
            border-color: v.$white-tint-40 !important;

            &:hover {
                background-color: v.$dark-bluish;
            }
        }

        .icon-container,
        .btn-arrow-down {
            color: v.$white-tint-40;
        }
    }

    .--color-tint {
        color: v.$white-tint-40;
    }
}

.t-white {
    background-color: v.$white;

    .tag-and-title {
        color: v.$dark-bluish;
        border-color: v.$dark-bluish !important;
    }

    .paragraph {
        color: v.$dark-bluish-72;
    }

    // Styles for borders
    .--border {
        color: v.$dark-bluish;
        border-color: v.$dark-bluish-tint-40 !important;
    }

    // Styles for shadows
    &.shadow-outer {
        @include m.outer-shadow-light;
    }

    .shadow-outer {
        @include m.outer-shadow-light;
    }

    &.shadow-inner {
        @include m.inner-shadow-light;
    }

    .shadow-inner {
        @include m.inner-shadow-light;
    }

    // Styles for btns
    .btn {
        background-color: v.$white;
        color: v.$dark-bluish;
        // border: 1px v.$white solid;
        @include m.outer-shadow-light;

        @include m.screen("md") {
            &:hover {
                @include m.inner-shadow-light;
            }
        }

        &.outlined {
            &:hover {
                background-color: v.$dark-bluish;
                color: v.$white;
                border-color: v.$dark-bluish !important;
            }
        }
    }

    .link {
        a {
            color: v.$dark-bluish;

            svg {
                path {
                    fill: v.$dark-bluish;
                }
            }

            &::before {
                background-color: v.$dark-bluish;
            }
        }
    }

    .form {
        &-input-container {
            background-color: v.$white;
        }

        &-input {
            border-color: v.$dark-bluish-tint-40 !important;

            &:hover {
                background-color: v.$creamy-white;
            }
        }
    }

    .--color-tint {
        color: v.$dark-bluish-tint-40;
    }
}
