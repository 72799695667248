@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.contact-form {
    display: grid;

    grid-template-columns: 1fr;

    @include m.screen("md") {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
    }

    input[name="first_name"] {
        grid-column: 1/2;
    }
}
