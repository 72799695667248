@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.hero-main {
    min-height: 100dvh;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &__container {
        gap: f.px2rem(16);
        height: auto;
        z-index: 1;
        padding-top: f.px2rem(112);
        
        .tag-and-title, .--richtext {
            grid-column: 1 / -1;
        }

        .--border {
            border-bottom: 1px solid;
            grid-column: 1 / -1;
            margin-top: f.px2rem(16);
            margin-bottom: f.px2rem(16);
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(16);
        grid-column: 1 / -1;
        margin-top: f.px2rem(32);
    }

    &__video, &__image {
        position: absolute;
        top: 0;
        min-height: 100dvh;
        width: 100dvw;
        object-fit: cover;
    }

    .scroll-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.6s ease;
        pointer-events: none;
    }

    @include m.screen("sm") {
        &__container {
            padding-top: f.px2rem(128);

            .--border {
                margin-top: f.px2rem(32);
                margin-bottom: f.px2rem(32);
            }
        }
        
        &__ctas {
            align-items: flex-start;
        }
    }

    @include m.screen("md") {
        &__container {
            padding-top: f.px2rem(128);
        }
        
        .text-tag {
            grid-column: 1 / span 6;
        }

        .text-great-hero {
            grid-column: 1 / span 10;
        }

        .paragraph {
            grid-column: 1 / span 7;
        }
    }


    @include m.screen("xl") {
        &__container {
            gap: f.px2rem(32);
            padding-top: f.px2rem(256);
        }

        .text-great-hero {
            grid-column: 1 / span 9;
        }

        &__ctas {
            margin-top: f.px2rem(64);
        }
    }
}