/**
 * Container
 * A component fox fixing an elements witdth to the current breakpoint
 * Based on Tailwind CSS breakpoints
 * https://tailwindcss.com/docs/container
 */

@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;

.container {
    @include m.screen("sm") {
        max-width: v.$sm;
    }

    @include m.screen("md") {
        max-width: v.$md;
    }

    @include m.screen("lg") {
        max-width: v.$lg;
    }

    @include m.screen("xl") {
        max-width: v.$xl;
    }

    width: 100%;
    margin: 0 auto;
}

/**
 * Wrapper
 * Its like a Bootstrap container but with padding and centered
 */
.wrapper {
    position: relative;

    display: block;

    width: 100%;
    height: 100%;

    max-width: v.$max_width_wrapper;
    padding-right: f.px2rem(16);
    padding-left: f.px2rem(16);
    margin-right: auto;
    margin-left: auto;

    @include m.screen("md") {
        padding-right: f.px2rem(30);
        padding-left: f.px2rem(30);
    }
}

/*******************************************
************* SHADOW CLASSES ***************
*******************************************/
/*
    Because the nature of the project:
    Using reusable components with with toggling options for their properties
    its better to handle that changes with classes, so it's easier to control them
    in Wagtail Admin
*/
.outer-shadow-light {
    @include m.outer-shadow-light();
}

.inner-shadow-light {
    @include m.inner-shadow-light();
}

.outer-shadow-dark {
    @include m.outer-shadow-light();
}

.inner-shadow-dark {
    @include m.inner-shadow-light();
}
