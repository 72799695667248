@use "./functions" as f;
@use "./variables" as v;
@use "./mixins" as m;

.--richtext {
    b,
    strong {
        font-weight: 700;
    }

    a {
        color: inherit;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 2rem 0;
    }

    hr {
        display: block;
        margin: 1.5rem 0;
        border-top: 1px solid black;
        border-bottom: 0;

        & ~ p {
            margin-top: 2rem;
        }
    }

    ul {
        list-style-type: "-";
        padding-left: f.px2rem(8);

        li {
            margin-bottom: f.px2rem(8);
            padding-left: f.px2rem(8);

            @include m.screen("md") {
                margin-bottom: f.px2rem(16);
            }
            @include m.screen("xl") {
                margin-bottom: f.px2rem(32);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    p {
        margin-bottom: f.px2rem(8);

        @include m.screen("md") {
            margin-bottom: f.px2rem(16);
        }
        @include m.screen("xl") {
            margin-bottom: f.px2rem(32);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.--grid {
    @include m.grid();
}

.padding_top {
    padding-top: f.px2rem(64);

    @include m.screen("sm") {
        padding-top: f.px2rem(128);
    }

    @include m.screen("xl") {
        padding-top: f.px2rem(256);
    }
}

.padding_bottom {
    padding-bottom: f.px2rem(64);

    @include m.screen("sm") {
        padding-bottom: f.px2rem(128);
    }

    @include m.screen("xl") {
        padding-bottom: f.px2rem(256);
    }
}

.align-center {
    text-align: center;

    .--grid {
        place-content: center;
    }
}

.align-left {
    text-align: left;
}

.height-full {
    min-height: 100dvh;

    .--grid {
        height: max-content;
    }
}

.height-auto {
    height: auto;
}

.border-radius-top {
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
}

.border-radius-bottom {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}