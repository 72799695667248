@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.header {
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 10;

    opacity: 1;
    width: 100%;
    display: block;

    &.--open {
        min-height: 100dvh;
        background-color: v.$white;

        .header__nav {
            padding: 1rem;
            border-bottom: v.$dark-bluish-tint-20;

            .header__logo {
                p {
                    color: v.$dark-bluish !important;
                }
            }
        }

        .header__mobile {
            display: flex;
            flex-direction: column;

            .header__option {
                padding-top: f.px2rem(16);
                padding-bottom: f.px2rem(16);
                border-top: 1px solid v.$dark-bluish-tint-20;
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                transition: color 0.2s ease-in-out;

                &:last-of-type {
                    border-bottom: 1px solid v.$dark-bluish-tint-20;
                }

                .submenu {
                    height: 0;
                    position: absolute;
                    top: 100%;
                    opacity: 0;
                    width: 100%;
                    padding-left: 0;
                    transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;

                    li {
                        padding-top: f.px2rem(16);
                        padding-bottom: f.px2rem(16);
                        border-top: 1px solid v.$dark-bluish-tint-20;
                        transition: color 0.2s ease-in-out;
                    }
                }

                &.--inactive {
                    color: v.$dark-bluish-tint-40;
                }

                &.--active {
                    border-top-color: v.$dark-bluish;

                    .submenu {
                        display: block;
                        opacity: 1;
                        height: auto;
                    }

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

        }

        .header__burger {
            display: none;
        }

        .header__close {
            display: block;
        }
    }

    &.--fixed {
        .header__nav {
            background: rgba(251, 251, 251, 0.45);
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1);
        //     backdrop-filter: blur(40px);
        //     box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.10);
        //     border-color: transparent !important;

        //     .header__burger {
        //         svg {
        //             stroke: v.$dark-bluish !important;
        //         }
        //     }

        //     .header__logo {
        //         p {
        //             color: v.$dark-bluish !important;
        //         }
        //     }
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        background: rgba(251, 251, 251, 0.45);
        padding: f.px2rem(16);
        border: 1px solid rgba(242, 241, 235, 0.20);
        transition: all 0.3s ease-in-out;
        height: auto;
        width: 100%;
        backdrop-filter: blur(40px);

        .header__burger, .header__close {
            background-color: transparent;
            outline-color: transparent;
            border-color: transparent;
            height: f.px2rem(24);

            svg {
                height: f.px2rem(24);
            }
        }

        .header__close {
            display: none;
        }

        .header__option {
            width: max-content;

            a {
                width: max-content;
            }

            svg {
                width: max-content;
            }
        }
    }

    &__desktop {
        display: none;
    }

    &__mobile {
        display: none;
        position: absolute;
        width: 100%;
        padding-left: 0;
        top: 100%;
        left: 0;
        height: auto;
    }

    &__logo {
        display: flex;
        align-items: flex-end;
        gap: f.px2rem(8);

        svg {
            height: f.px2rem(40);
        }

        p {
            @include m.font-size(16, 500, 1.5, 0);
        }
    }

    ul {
        list-style: none;

        a, li {
            transition: all 0.3s ease-in-out;
        }
    }

    button {
        padding: 0;
        border: none;
        width: max-content;
        display: block;
    }

    @include m.screen("md") {


        .header__nav {
            backdrop-filter: blur(40px);
            transition: all 0.3s ease-in-out;
        }

        .header__desktop {
            display: flex;
            height: max-content;
            padding-right: f.px2rem(16) !important;
            gap: f.px2rem(32);

            .submenu {
                height: 0;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                padding-left: 0;
            }
        }

        .header__burger, .header__close, .header__mobile {
            display: none !important;
        }

        .header__logo {
            display: flex;
            align-items: flex-end;
        }

        .header__option {
            cursor: pointer;
            position: relative;

            .text-p {
                line-height: 1;
            }

            .option {
                border-bottom: 2px solid transparent;
                transition: none;
            }

            .submenu {
                position: absolute;
                opacity: 0;
                transition: opacity 0.6s ease-in-out;
                width: max-content;
            }

            &:nth-last-child(-n + 2) {
                .submenu {
                    right: 0;
                    text-align: right;
                }
            }

            &.--active {
                &:nth-last-child(-n + 2) {
                    .submenu {
                        right: 0;
                        text-align: right;
                    }
                }

                .option {
                    border-bottom: 2px solid v.$bluish-teal;
                    color: v.$bluish-teal !important;
                }

                .submenu {
                    height: auto;
                    overflow: initial;
                    padding-top: f.px2rem(32);
                    padding-bottom: f.px2rem(32);
                    display: flex;
                    flex-direction: column;
                    gap: f.px2rem(16);
                    width: max-content;
                    text-align: left;
                    opacity: 1;

                    a {
                        color: v.$bluish-teal !important;
                    }
                }
            }
        }

        &.--active, &.--fixed {
            .header__nav {
                background: rgba(251, 251, 251, 0.45);
                box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1);
                padding: f.px2rem(16) f.px2rem(19) f.px2rem(16) f.px2rem(16);
                border: 1px solid;
                align-items: flex-start;
                height: max-content;
                transition: all 0.3s ease-in-out;

                .header__logo {
                    gap: f.px2rem(32);
                    transition: all 0.3s ease-in-out;

                    svg {
                        transition: all 0.3s ease-in-out;
                        height: f.px2rem(56);
                        width: f.px2rem(56);
                    }

                    p {
                        @include m.font-size(20, 400, 1, -2);
                        transition: all 0.3s ease-in-out;
                    }
                }

                .header__desktop {
                    padding-top: f.px2rem(20.4);
                    transition: all 0.3s ease-in-out;

                    a {

                    }

                    .option {
                        padding-bottom: f.px2rem(16);
                    }
                }

                &:hover {
                    background-color: v.$white;
                    box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.25);
                    border: 1px solid transparent;

                    a {
                        color: v.$bluish-teal;
                        mix-blend-mode: inherit;
                    }

                    p {
                        color: v.$bluish-teal;
                        mix-blend-mode: inherit;
                    }

                    .header__logo {
                        gap: f.px2rem(32);
                        transition: all 0.3s ease-in-out;

                        svg {
                            transition: all 0.3s ease-in-out;
                            height: f.px2rem(56);
                            width: f.px2rem(56);
                        }

                        p {
                            @include m.font-size(20, 400, 1, -2);
                            transition: all 0.3s ease-in-out;
                            color: v.$bluish-teal !important;
                        }
                    }

                    .header__desktop {
                        padding-top: f.px2rem(20.4);
                        transition: all 0.3s ease-in-out;

                        .header__option {
                            a {
                                color: v.$bluish-teal;
                                transition: all 0.3s ease-in-out;
                            }

                            .text-p {
                                line-height: 1;
                            }

                            &.--active {
                                a {
                                    color: v.$bluish-teal;
                                }
                            }

                            &.--inactive {
                                a {
                                    color: v.$dark-bluish-tint-40;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.--fixed {
            .header__nav {
                background-color: rgba(251, 251, 251, 0.45);
                box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.10);
                border-bottom: v.$dark-bluish-tint-20;

                a {
                    color: v.$bluish-teal;
                    mix-blend-mode: inherit;
                }

                p {
                    color: v.$bluish-teal;
                    mix-blend-mode: inherit;
                }

                .header__logo {
                    gap: f.px2rem(32);
                    transition: all 0.3s ease-in-out;

                    svg {
                        transition: all 0.3s ease-in-out;
                        height: f.px2rem(56);
                        width: f.px2rem(56);
                    }

                    p {
                        @include m.font-size(20, 400, 1, -2);
                        transition: all 0.3s ease-in-out;
                        color: v.$bluish-teal !important;
                    }
                }

                .header__desktop {
                    padding-top: f.px2rem(20.4);
                    transition: all 0.3s ease-in-out;

                    .header__option {
                        color: v.$bluish-teal !important;
                        a {
                            color: v.$bluish-teal;
                            transition: all 0.3s ease-in-out;
                            mix-blend-mode: inherit;
                        }

                        .text-p {
                            line-height: 1;
                        }

                        &.--active {
                            a {
                                color: v.$bluish-teal;
                            }
                        }

                        &.--inactive {
                            a {
                                color: v.$bluish-teal;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: v.$white;
                    box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.25);

                    // border-radius: f.px2rem(16);
                    // padding: f.px2rem(16) f.px2rem(19) f.px2rem(16) f.px2rem(16);
                    // border: 1px solid;
                    // align-items: flex-start;
                    // height: max-content;
                    // transition: all 0.3s ease-in-out;

                    // .header__desktop {
                    //     padding-top: f.px2rem(38);

                    //     .header__option {
                    //         .option {
                    //             padding-bottom: f.px2rem(16);
                    //         }

                    //         a {
                    //             color: v.$bluish-teal;
                    //             transition: all 0.3s ease-in-out;
                    //         }

                    //         &.--active {
                    //             a {
                    //                 color: v.$bluish-teal;
                    //             }
                    //         }

                    //         &.--inactive {
                    //             a {
                    //                 color: v.$dark-bluish-tint-40;
                    //             }
                    //         }
                    //     }
                    // }

                    // .header__logo {
                    //     gap: f.px2rem(32);
                    //     transition: all 0.3s ease-in-out;
                    //     align-items: flex-end;

                    //     svg {
                    //         transition: all 0.3s ease-in-out;
                    //         height: f.px2rem(56);
                    //         width: f.px2rem(56);
                    //     }

                    //     p {
                    //         @include m.font-size(20, 400, 1, -2);
                    //         transition: all 0.3s ease-in-out;
                    //         color: v.$bluish-teal !important;
                    //     }
                    // }
                }
            }
        }
    }

    @include m.screen("xl") {
        // &.--active, &.--fixed {
        //     .header__nav {
        //         padding: f.px2rem(40) f.px2rem(32) 0 f.px2rem(28);
        //         border-radius: f.px2rem(32);
        //     }

        //     .header__option {
        //         .option {
        //             padding-bottom: f.px2rem(32);
        //         }
        //     }

        //     .header__logo {
        //         gap: f.px2rem(32);

        //         p {
        //             @include m.font-size(32, 400, 1.5, -1);
        //         }

        //         svg {
        //             height: f.px2rem(82);
        //             width: f.px2rem(100);
        //         }
        //     }
        // }

        // &.--fixed {
        //     .header__nav {
        //         padding: f.px2rem(32) f.px2rem(32) 0 f.px2rem(28);
        //         border-radius: f.px2rem(64);

        //         .header__logo {
        //             gap: f.px2rem(32);

        //             p {
        //                 @include m.font-size(32, 400, 1.5, -1);
        //             }

        //             svg {
        //                 width: f.px2rem(81);
        //                 height: f.px2rem(66);
        //             }
        //         }
        //     }
        // }

        &.--fixed, &.--active {
            .header__nav {
                .header__logo {
                    margin-bottom: f.px2rem(32);
                    gap: f.px2rem(32);

                    p {
                        @include m.font-size(32, 400, 1.5, -1);
                    }

                    svg {
                        height: f.px2rem(82);
                        width: f.px2rem(100);
                    }
                }

                .header__desktop {
                    gap: f.px2rem(64);
                    margin-bottom: f.px2rem(32);
                }

                &:hover {
                    .header__logo {
                        margin-bottom: f.px2rem(32);
                        gap: f.px2rem(32);

                        p {
                            @include m.font-size(32, 400, 1.5, -1);
                        }

                        svg {
                            height: f.px2rem(82);
                            width: f.px2rem(100);
                        }
                    }
                }
            }

            .header__option {
                .option {
                    padding-bottom: f.px2rem(32);
                }

                &.--active {
                    .submenu {
                        gap: f.px2rem(32);
                        padding-top: f.px2rem(64);
                        padding-bottom: f.px2rem(64);
                    }
                }
            }
        }
    }
}
