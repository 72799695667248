@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.text-with-media-video-link {
    &.height-full {
        display: block;
        margin-top: auto;
        margin-bottom: auto;

        .text-with-media-video-link__container, .text-with-media-video-link__video {
            img {
                box-shadow: none;
            }
        }
    }

    &__content {
        grid-column: 1 / -1;
        padding-top: f.px2rem(64);
        padding-bottom: f.px2rem(64);
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 1rem;

        .text-tag {
            margin-bottom: f.px2rem(32);
        }

        .text-statement {
            margin-bottom: f.px2rem(12);
        }

        .paragraph {
            margin-bottom: f.px2rem(32);
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(12);
    }

    &__video {
        grid-column: 1 / -1;
        width: 100dvw;
        margin-left: -1rem;
        position: relative;

        .video {
            width: 100%;
            height: 375px;
            object-fit: cover;
            position: relative;
            top: 0;

            &-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 375px;
                object-fit: cover;                
            }
            
            &-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                transition: all 1s ease-in-out;
            }
        }
    }   

    @include m.screen(600px) {
        &__content {
            padding-top: f.px2rem(128);
            padding-bottom: f.px2rem(128);
    
            .text-tag {
                margin-bottom: f.px2rem(64);
            }
    
            .text-statement {
                margin-bottom: f.px2rem(24);
            }
    
            .paragraph {
                margin-bottom: f.px2rem(64);
            }
        }

        &__video {
            .video {
                height: 600px;
    
                &-thumbnail {
                    height: 600px;
                }
            }
        }

        &__ctas {
            gap: f.px2rem(24);
        }
    }

    @include m.screen("sm") {
        &__video {
            margin-left: -1.5rem;
        }
    }

    @include m.screen("md") {
        &__content {
            padding-top: 0;
            padding-bottom: 0;
            grid-row: 1;
            padding-right: 1.5rem;
        }

        &__video {
            grid-row: 1;
            width: auto;
            margin-left: auto;

            &.wrapped {
                border-radius: f.px2rem(16);
                width: 100%;
                height: auto;
                
                .video {
                    height: 100%;
                    border-radius: f.px2rem(16);
                    min-height: 25rem;
                    
                    &-thumbnail {
                        height: 100%;
                        border-radius: f.px2rem(16);
                    }
                }
            }

            &.full {
                height: 100%;

                .video {
                    height: 100%;
                    min-height: 25rem;
    
                    &-thumbnail {
                        height: 100%;
                    }
                }
            }
        }
        
        &.height-full {
            .text-with-media-video-link__container {
                padding-top: 0;
                padding-bottom: 0;
                grid-row: 1;
            }

            .--grid {
                min-height: 100dvh;
            }
        }

        &.text-left {
            .text-with-media-video-link__content {
                grid-column: 1 / span 6;
                padding-right: f.px2rem(56);

                span {
                    border-left: 1px solid;
                    display: block;
                    padding-left: f.px2rem(48);
                }
            }
    
            .text-with-media-video-link__video {
                grid-column: 7 / span 6;
                
                &.full {
                    width: 100%;
                    margin-right: -2rem;
                }
            }

            .text-with-media-video-link__ctas {
                padding-left: f.px2rem(32);
            }
        }

        &.text-right {
            &.height-full {
                .--grid {
                    padding-left: 0;
                }
            }

            .text-with-media-video-link__content {
                grid-column: 7 / span 6;
                padding-left: f.px2rem(56);

                span {
                    border-right: 1px solid;
                    display: block;
                    padding-right: f.px2rem(48);
                }
            }

            .text-with-media-video-link__video {
                grid-column: 1 / span 6;

                &.full {
                    margin-left: -2rem;
                }
            }
        }

        &__ctas {
            align-items: flex-start;
        }
    }

    @include m.screen("xl") {
        &__content {
            padding-top: f.px2rem(256);
            padding-bottom: f.px2rem(256);
            
            .text-tag {
                margin-bottom: f.px2rem(128);
            }
    
            .text-statement {
                margin-bottom: f.px2rem(48);
            }
    
            .paragraph {
                margin-bottom: f.px2rem(96);
            }
        }

        &.text-left {
            .text-with-media-video-link__content {
                padding-right: f.px2rem(112);

                span {
                    padding-left: f.px2rem(96);
                }
            }

            .text-with-media-video-link__ctas {
                padding-left: f.px2rem(64);
            }
        }

        &.text-right {
            .text-with-media-video-link__content {
                padding-left: f.px2rem(112);

                span {
                    padding-right: f.px2rem(96);
                }
            }
        }

        &__ctas {
            gap: f.px2rem(48);
        }
    }
}