// Import this in all scss files where you need them
@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.btn {
    min-width: max-content;
    width: 100%;
    border: none;
    padding: f.px2rem(12) f.px2rem(16);
    border-radius: v.$border-btns;
    cursor: pointer;

    @include m.flex-center();
    @include m.transition-all;
    @include m.font-size(16, 400, 1.5, -1);

    @include m.screen("sm") {
        width: max-content;
        padding: f.px2rem(16) f.px2rem(24);
        @include m.flex-center();
        @include m.font-size(20, 400, 1.5, -1);
    }

    @include m.screen("xl") {
        padding: f.px2rem(24) f.px2rem(32);
        @include m.font-size(40, 400, 1.5, -1);
    }

    &.outlined {
        background-color: transparent;
        box-shadow: none;
        border: 1px solid;

        &:hover {
            box-shadow: none;
        }
    }
}

.link {
    a, span {
        width: max-content;
        border: none;
        cursor: pointer;
        position: relative;
        padding-right: f.px2rem(14);

        @include m.transition-all;
        @include m.flex-center();
        @include m.font-size(16, 400, 1.5, -1);

        .arrow {
            position: absolute;
            right: 0;
            opacity: 1;
            height: f.px2rem(14);
            width: f.px2rem(14);
            margin-left: f.px2rem(4);
            @include m.transition-all;
        }

        &:hover {
            .arrow {
                transform: translateX(4px);
                opacity: 0;
            }
        }
    
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 0.875rem);
            height: 1px;
            z-index: 1;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease-in-out;
        }

        &:hover::before {
            transform: scaleX(1);
        }
    }

    &[data-animation="bonus"] {
        a::before {
            transform-origin: right;
        }

        span::before {
            transform-origin: right;
            background-color: v.$white;
        }
    }
    
    &[data-animation="bonus"] {
        a:hover::before {
            transform-origin: left;
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
        }

        span:hover::before {
            transform-origin: left;
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
            background-color: v.$white;
        }
    }

    @include m.screen("sm") {
        a, span {
            @include m.font-size(20, 400, 1.5, -1);
            padding-right: f.px2rem(18);
    
            .arrrow {
                height: f.px2rem(18);
                width: f.px2rem(18);
            }
    
            &::before {
                width: calc(100% - 1.125rem);
            }
        }
    }

    @include m.screen("xl") {
        a, span {
            @include m.font-size(40, 400, 1.5, -1);
            padding-right: f.px2rem(33);
    
            .arrow {
                height: f.px2rem(33);
                width: f.px2rem(33);
            }
    
            &::before {
                width: calc(100% - 2.0625rem);
            }
        }
    }
}