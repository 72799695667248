@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.image-component {
    &__container {
        padding-left: 0;
        padding-right: 0;
    }

    &__element {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @include m.screen("md") {
        &.wrapped {
            .image-component__container {
                padding-left: f.px2rem(32);
                padding-right: f.px2rem(32);
            }

            .image-component__element {
                border-radius: f.px2rem(24);
            }
        }

        &.full-width {
            .shadow-outer {
                box-shadow: none;
            }
        }
    }
}