// Import this in all scss files where you need them
@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.storybook {
    display: flex;
    flex-direction: column;

    @include m.screen("md") {
        flex-direction: row;
        justify-content: space-between;
    }
    
    h1, h2 {
        margin-bottom: 2rem;
        margin-top: 4rem;
    }

    .test-text {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;

        @include m.screen("md") {
            width: 32%;
            padding: 5rem;
        }
    }
}


