@use "../abstracts/mixins" as m;
@use "../abstracts/variables" as v;

// Fonts
@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/fonts/Inter-Black.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/fonts/Inter-ExtraBold.woff2")
            format("woff2"),
        url("/static/website/fonts/Inter-ExtraBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-Bold.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-SemiBold.woff2") format("woff2"),
        url("/static/website/fonts/Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-Regular.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-Medium.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-Light.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-ExtraLight.woff2") format("woff2"),
        url("/static/website/fonts/Inter-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("/static/website/fonts/Inter-Thin.woff2") format("woff2"),
        url("/static/website/fonts/Inter-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

// Typography
.text-great-hero {
    @include m.font-size(60, 400, 1, 0);

    @include m.screen("sm") {
        @include m.font-size(80, 400, 1, -2);
    }

    @include m.screen("lg") {
        @include m.font-size(100, 400, 1, -2);
    }

    @include m.screen("xl") {
        @include m.font-size(200, 400, 1, -2);
    }
}

.text-statement {
    @include m.font-size(28, 300, 1.3, -2);

    @include m.screen("sm") {
        @include m.font-size(34, 300, 1.3, -2);
    }

    @include m.screen("xl") {
        @include m.font-size(68, 300, 1.3, -2);
    }
}

.text-big-p {
    @include m.font-size(16, 300, 1.5, -1);

    @include m.screen("sm") {
        @include m.font-size(20, 300, 1.5, -1);
    }

    @include m.screen("xl") {
        @include m.font-size(36, 300, 1.5, 0);
    }
}

.text-p-title {
    @include m.font-size(16, 700, 1.5, -1);

    @include m.screen("sm") {
        @include m.font-size(20, 700, 1.5, -1);
    }

    @include m.screen("xl") {
        @include m.font-size(40, 700, 1.5, -1);
    }
}

.text-p {
    @include m.font-size(16, 400, 1.5, -1);

    @include m.screen("sm") {
        @include m.font-size(20, 400, 1.5, -1);
    }

    @include m.screen("xl") {
        @include m.font-size(40, 400, 1.5, -1);
    }
}

.text-tag {
    text-transform: uppercase;
    @include m.font-size(13, 400, 1, 4);

    @include m.screen("sm") {
        @include m.font-size(15, 400, 1, 4);
    }

    @include m.screen("xl") {
        @include m.font-size(30, 400, 1, 4);
    }
}
