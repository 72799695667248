@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.form {
    input[name="recaptcha-token"] {
        display: none;
    }

    &-input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: f.px2rem(70);

        &.--textarea {
            min-height: 8rem;

            .form-control {
                min-height: f.px2rem(120);
            }
        }

        .form-input-message {
            height: f.px2rem(16.1);
        }

        .icon-person {
            display: block;
        }

        .icon-error, .text-error {
            display: none;
        }

        &.--error {
            color: v.$orange;

            .form-input {
                border-color: v.$orange;
            }

            .form-label,
            select {
                color: v.$orange;
            }

            .form-input-message {
                color: v.$orange;

                p {
                    visibility: visible;
                }
            }

            .icon-normal {
                display: none;
            }

            .icon-error {
                display: block;
            }

            .text-error {
                display: block;
                @include m.font-size(12, 400, 1, 1);
                position: absolute;
                bottom: f.px2rem(-18);

                @include m.screen("sm") {
                    @include m.font-size(14, 400, 1, 1);
                }

                @include m.screen("xl") {
                    @include m.font-size(24, 400, 1, 1);
                    bottom: -2rem;
                }
            }

            #spanClick {
                border: 1px solid v.$orange !important;
            }

            &.input-cv {
                .text-big-p {
                    color: v.$orange;
                }
            }
        }
    }

    &-control {
        position: relative;

        height: 100%;

        // padding: 0 1rem;
    }

    &-label {
        position: absolute;
        top: 1.4rem;
        width: 100%;
        transition: all 0.2s ease;
        z-index: 1;
        pointer-events: none;
    }

    &-input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 1.1rem 1rem 0rem 0;
        resize: none;
        border: none;
        border-bottom: 1px transparent solid;
        outline: none;
        background: transparent;

        &::placeholder {
            font-size: f.px2rem(14);
            opacity: 0;
            visibility: hidden;
            color: transparent;
        }

        &:focus ~ .form-label,
        &:not(select):not(:placeholder-shown).form-input:not(:focus)
            ~ .form-label {
            top: f.px2rem(5);
            z-index: 9;
            transition: all 0.2s ease;
            @include m.font-size(12, 400, 1.5, -1);

            @include m.screen("sm") {
                @include m.font-size(14, 400, 1.5, -1);
            }

            @include m.screen("xl") {
                top: f.px2rem(5);
                @include m.font-size(24, 400, 1.5, -1);
            }
        }

        &:focus::placeholder {
            opacity: 1;
            visibility: visible;
        }

        &:focus {
            // border-bottom: 2px green solid;
        }

        &:hover {
            // background-color: pink;
            transition: all v.$timming ease-in-out;
        }
    }

    select {
        appearance: none;

        padding-top: 0;
    }

    &-input-message {
        font-size: f.px2rem(14);
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        p {
            visibility: hidden;
        }
    }

    &-areas {
        resize: none;
        max-width: 100%;
        min-height: 7rem;
        max-height: 16rem;
    }

    .icon-container,
    .btn-arrow-down {
        cursor: pointer;

        position: absolute;

        top: 50%;
        right: f.px2rem(8);

        transform: translateY(-50%);
        z-index: 1;

        .icon-close {
            width: f.px2rem(12);
        }
    }

    .message-text {
        color: v.$orange;

        text-align: left;

        a {
            text-decoration: underline;
        }

        br {
            display: none;

            @include m.screen("lg") {
                display: block;
            }
        }
    }

    .form-areas {
        min-height: auto;
    }

    textarea {
        padding-top: 1.5rem;
    }

    .btn-arrow-down {
        border: none;
        background-color: transparent;
    }

    @include m.screen("xl") {
        &-input-container {
            height: f.px2rem(176);

            .form-label {
                top: f.px2rem(54);
            }
            
            .form-control {
                .icon-container {
                    svg {
                        height: f.px2rem(40) !important;
                        width: f.px2rem(40) !important;
                    }
                }
            }
        }

        &-input {
            border-bottom: 2px solid;
        }
    }
}

input {
    font-size: 80px;
}

.input-cv { 
    .form-input:focus ~ .form-label,
    .form-input:not(select):not(:placeholder-shown).form-input:not(:focus)
    ~ .form-label {
        @include m.font-size(14, 400, 1.5, -1);

        @include m.screen("sm") {
            @include m.font-size(16, 400, 1.5, -1);
        }
    
        @include m.screen("xl") {
            @include m.font-size(32, 400, 1.5, -1);
        }
    }

    .text-error {
        bottom: -120% !important;

        @include m.screen("sm") {
            bottom: -150% !important;
        }

        @include m.screen("xl") {
            bottom: f.px2rem(-24) !important;
        }
    }
}