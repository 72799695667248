@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

* {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

body {
    font-family: "Inter", sans-serif;

    color: v.$dark-bluish;
}

button {
    color: v.$dark-bluish;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: f.px2rem(78);
}