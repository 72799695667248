// --------------------------------------------------
// Media Query Mixin
// By default uses min width breakpoints
// Change to max width if needed
// --------------------------------------------------
@use "sass:math";
@use "./variables" as v;
@use "./functions" as f;

/*
    $sm: 744px;
    $md: 1024px;
    $lg: 1280px;
    $xl: 2560px;
*/

@mixin screen($screen-size) {
    @if $screen-size == "xs" {
        @media (max-width: (v.$sm - 1px)) {
            @content;
        }
    } @else if $screen-size == "sm" {
        @media (min-width: (v.$sm)) {
            @content;
        }
    } @else if $screen-size == "md" {
        @media (min-width: (v.$md)) {
            @content;
        }
    } @else if $screen-size == "lg" {
        @media (min-width: (v.$lg)) {
            @content;
        }
    } @else if $screen-size == "xl" {
        @media (min-width: (v.$xl)) {
            @content;
        }
    } @else {
        @media (min-width: ($screen-size)) {
            @content;
        }
    }
}

// --------------------------------------------------
// Fonts
// --------------------------------------------------
@mixin font-size(
    $size: 16,
    $weight: 400,
    $line-height: normal,
    $letter-spacing: normal
) {
    font-size: $size + px;
    font-size: f.px2rem($size);
    font-weight: $weight;

    @if $line-height == normal {
        line-height: normal;
    } @else {
        line-height: $line-height;
    }

    @if $letter-spacing == normal {
        letter-spacing: normal;
    } @else {
        letter-spacing: #{math.div($letter-spacing * $size, 100)}px;
    }
}

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: $font-name, system-ui, sans-serif;
        src: url($path + ".woff2") format("woff2"),
            url($path + ".woff2") format("woff2");
        font-weight: $weight;
        font-style: $style;
    }
}

// Image mixins
@mixin image-responsive($display: block) {
    display: $display;
    max-width: 100%;
    height: auto;
}

@mixin grid() {
    display: grid;

    grid-template-columns: repeat(4, 1fr);

    grid-column-gap: 0.5rem;

    // Im using padding because there are sections that are full width
    // Example: if you need a bg color to the complete area
    // if you use margin it will not paint the margin area because it is outside the element because of the box model layout
    // using padding we ensure that the bg color will be painted because the padding its inside the element
    padding-left: f.px2rem(16);
    padding-right: f.px2rem(16);

    max-width: v.$xl;

    // To center the grid when the viewport is > 2560px
    margin: 0 auto;

    @include screen(v.$sm) {
        grid-template-columns: repeat(12, 1fr);

        grid-column-gap: 1rem;

        padding-left: f.px2rem(24);
        padding-right: f.px2rem(24);
    }

    @include screen(v.$md) {
        padding-left: f.px2rem(32);
        padding-right: f.px2rem(32);
    }

    @include screen(v.$xl) {
        grid-column-gap: f.px2rem(32);
    }
}

// Custom Scrollbars
@mixin custom-scrollbars() {
    &::-webkit-scrollbar {
        width: 0.5em;
        padding-left: 10px;
    }

    &.--horizontal-scroll {
        &::-webkit-scrollbar {
            height: 0.5em;
        }
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        outline: 0;
        border-radius: 5px;
    }
}

// Border Radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// Text truncate
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Flex toolkit
@mixin flex-center($direction: row) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}

// Grid row
// @mixin grid-rows($rows-mobile, $rows-tablet, $rows-desktop) {
//     grid-template-rows: repeat($rows-mobile, 1rem);

//     @include screen(v.$md) {
//         grid-template-rows: repeat($rows-tablet, 1rem);
//     }

//     @include screen(v.$lg) {
//         grid-template-rows: repeat($rows-desktop, 1rem);
//     }
// }

// SHADOWS
@mixin outer-shadow-light {
    box-shadow: inset 2px 2px 8px 0 rgba(0, 0, 0, 0),
        inset -3px -3px 8px 0 rgba(155, 196, 242, 0), 8px 8px 24px 0px #0000001f,
        -6px -6px 18px 0 #ffffffff;
}

@mixin inner-shadow-light {
    box-shadow: inset 1px 1px 12px 0px #0000001f,
        inset -3px -3px 8px 0 #ffffffb2, 6px 6px 18px 0 rgba(0, 0, 0, 0);
}

@mixin outer-shadow-dark {
    box-shadow: inset 2px 2px 8px 0 rgba(0, 0, 0, 0),
        inset -3px -3px 8px 0 rgba(155, 196, 242, 0),
        -4px -4px 25px 0px #ffffff12, 4px 4px 15px 0px #00000026;
}

@mixin inner-shadow-dark {
    box-shadow: 9px 9px 15px 0px #00000026 inset,
        -6px -6px 15px 0px #ffffff12 inset, 6px 6px 18px 0 rgba(0, 0, 0, 0);
}

// ANIMATIONS
@mixin transition-all {
    transition: all 0.3s ease;
}