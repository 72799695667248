@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.footer {
    min-height: 100dvh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    position: relative;
    max-height: none;

    
    &__container {
        padding-top: f.px2rem(16);
        padding-bottom: f.px2rem(16);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100dvh;
    }

    &__top, &__middle, &__bottom {
        grid-column: 1 / -1;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: 1px solid;
        padding-bottom: f.px2rem(16);
        height: max-content;

        .footer-logo {
            height: f.px2rem(34);
            width: auto;
        }
    }

    &__middle {
        padding-top: f.px2rem(32);
        padding-bottom: f.px2rem(32);
        display: flex;
        flex-direction: column;
        gap: f.px2rem(48);
        border-bottom: 1px solid;
    }

    &__bottom {
        padding-top: f.px2rem(48);
        // display: flex;
        // flex-direction: column;
        // gap: f.px2rem(32);

        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 3rem;

        .text-p {
            color: v.$white-tint-40;
            margin-bottom:  f.px2rem(16);
        }

        ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            gap: f.px2rem(16);
        }

        .copyright {
            margin-top: f.px2rem(-16);
        }
    }

    @include m.screen("sm") {
        &__container {
            padding-top: f.px2rem(24);
            padding-bottom: f.px2rem(24);
        }

        &__top {
            padding-bottom: f.px2rem(32);

            .footer-logo {
                height: f.px2rem(52);
            }
        }

        &__middle {
            gap: f.px2rem(64);
            padding-bottom: f.px2rem(64);
        }

        &__bottom {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: f.px2rem(16);
            row-gap: f.px2rem(48);

            .copyright {
                margin-top: 0;
                margin-bottom: 0;
                grid-column: 1 / -1;
            }
        }
    }

    @include m.screen("md") {
        position: fixed;
        max-height: 100dvh;
        z-index: -2;

        &__container {
            padding-top: f.px2rem(32);
            padding-bottom: f.px2rem(32);
        }

        &__middle {
            @include m.grid();
            padding-left: 0 !important;
            padding-right: 0 !important;
            align-items: end;

            p {
                grid-column: 1 / span 8;
            }

            .btn {
                grid-column: 10 / -1;
                height: max-content;
            }
        }

        &__bottom {
            @include m.grid();
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: f.px2rem(32);

            .footer-section {
                grid-column: span 3;
            }
        }
    }

    @include m.screen("xl") {
        &__top {
            padding-bottom: f.px2rem(40);

            .footer-logo {
                height: f.px2rem(82);
            }
        }

        &__middle {
            padding-bottom: f.px2rem(96);
        }

        &__bottom {
            padding-top: f.px2rem(64);
        }
    }
}