@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.long-text-with-accordions {
    
    &__accordion__container {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
    }

    &__accordion__content {
        border-top: 1px solid;
        padding-top: f.px2rem(64);
        padding-bottom: f.px2rem(64);
        opacity: 0.4;

        &.active {
            opacity: 1;
        }

        &.first {
            padding-top: f.px2rem(32);
        }

        .category {
            margin-bottom: f.px2rem(32);
        }
    }

    &__chapters {
        display: none;
        padding-bottom: f.px2rem(64);
        height: max-content;
        position: sticky;
        top: f.px2rem(86);
    }

    @include m.screen("sm") {
        &__accordion__content {
            &.first {
                padding-top: f.px2rem(64);
            }
        }
    }

    @include m.screen("md") {
        .--border {
            &.top {
                width: 100%;
                grid-column: 1 / -1;
                border-top: 1px solid;
            }
        }

        &__chapters {
            display: flex;
            flex-direction: column;
            gap: f.px2rem(16);
            grid-column: 1 / span 6;
            padding-top: f.px2rem(64);

            a {
                filter: opacity(40%);
                width: max-content;
                transition: all 0.3s ease-in-out;

                &.active {
                    filter: opacity(100%);
                }
            }
        }

        &__accordion__container {
            grid-column: 7 / -1;
        }
        
        &__accordion__content {
            
            &.first {
                border-top: none;
            }
        }
    }

    @include m.screen("xl") {
        .--border {
            &.top {
                top: f.px2rem(100);
            }
        }

        &__accordion__container {
            padding-top: f.px2rem(128);
            padding-bottom: f.px2rem(128);
            gap: f.px2rem(64);
        }

        &__chapters {
            padding-top: f.px2rem(128);
            gap: f.px2rem(64);
            padding-bottom: f.px2rem(128);
            top: f.px2rem(100);
        }
    }
}