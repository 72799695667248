@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.accordion {
    &__container {
        &__content {
            list-style: none;
            padding-left: 0;
        }
    }

    &__visible-part {
        padding-top: f.px2rem(12);
        padding-bottom: f.px2rem(12);
        border-top: 1px solid v.$dark-bluish-tint-40;
        cursor: pointer;

        .accordion {
            display: flex;
            justify-content: space-between;

            .text-p {
                padding-right: f.px2rem(16);
            }

            .toggle-arrow {
                width: f.px2rem(24);
                height: f.px2rem(24);
                transition: transform 0.3s ease-in-out;

                path {
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        .panel {
            border: none;
            padding-right: f.px2rem(40);
            margin-top: f.px2rem(8);
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
        }
    }

    @include m.screen("sm") {
        &__visible-part {
            padding-top: f.px2rem(24);
            padding-bottom: f.px2rem(24);
        }
    }

    @include m.screen("xl") {
        &__visible-part {
            padding-top: f.px2rem(48);
            padding-bottom: f.px2rem(48);
            
            .accordion {
                .toggle-arrow {
                    width: f.px2rem(48);
                    height: f.px2rem(48);
                }
            }

            .panel {
                padding-right: f.px2rem(64);
            }
        }
    }
}