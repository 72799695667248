@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.job-opening-list {
    &.t-creamy-white {
        .job-opening-list__container {
            .job-opening-list__job {
                span {
                    background-color: v.$white;
                    color: v.$dark-bluish-72;
                }

                svg {
                    path {
                        fill: v.$dark-bluish-72;
                    }
                }
        
                &:hover {
                    background-color: v.$white;
                }
            }
        }
    }

    &.t-bluish-teal {
        .job-opening-list__container {
            .job-opening-list__job {
                span {
                    background-color: v.$dark-bluish-72;
                    color: v.$creamy-white;
                    transition: all 0.3s ease-in-out;
                }

                svg {
                    path {
                        fill: v.$creamy-white;
                    }
                }
        
                &:hover {
                    background-color: v.$dark-bluish-72;

                    span {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &.t-white {
        .job-opening-list__container {
            .job-opening-list__job {
                span {
                    background-color: v.$creamy-white;
                    color: v.$dark-bluish-72;
                }

                svg {
                    path {
                        fill: v.$dark-bluish-72;
                    }
                }
        
                &:hover {
                    background-color: v.$creamy-white;
                }
            }
        }
    }

    &__job {
        padding-top: f.px2rem(24);
        padding-bottom: f.px2rem(24);
        row-gap: f.px2rem(8);
        border-top: 1px solid;

        &.--grid {
            padding-left: 0;
            padding-right: 0;
        }

        p {
            grid-column: 1 / -1;
            grid-row: 1;
        }

        span {
            border-radius: f.px2rem(25);
            padding: f.px2rem(8) f.px2rem(16);
            display: block;
            width: max-content;
            grid-column: 1 / -1;
            grid-row: 2;
        }

        svg {
            display: none;
        }
    }

    @include m.screen("sm") {
        &__job {
            padding-top: f.px2rem(16);
            padding-bottom: f.px2rem(16);

            p {
                grid-column: 1 / span 9;
            }

            span {
                grid-column: 10 / -1;
                grid-row: 1;
                justify-self: end;
            }
        }
    }

    @include m.screen("md") {
        &__job {
            transition: all 0.3s ease-in-out;

            p {
                grid-column: 1 / span 7;
                transition: all 0.3s ease-in-out;
            }

            span {
                grid-column: 8 / span 4;
                grid-row: 1;
                justify-self: start;
            }

            svg {
                display: block;
                grid-column: -1;
                align-self: center;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                svg {
                    transform: translateX(-1rem);
                }

                p {
                    transform: translateX(1rem);
                }
            }
        }
    }

    @include m.screen("lg") {
        &__job {
            p {
                grid-column: 1 / span 6;
            }

            span {
                grid-column: 7 / span 5;
            }
        }
    }

    @include m.screen("xl") {
        &__job {
            padding-top: f.px2rem(32);
            padding-bottom: f.px2rem(32);

            span {
                padding: f.px2rem(24) f.px2rem(32);
                border-radius: f.px2rem(50);
            }
        }
    }
}