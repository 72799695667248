@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.hero-secondary {
    &__container {
        gap: f.px2rem(32);
        padding-top: f.px2rem(192);
        padding-bottom: f.px2rem(16);
        
        .tag-and-title, .--richtext {
            grid-column: 1 / -1;
        }
    }

    &__ctas {
        @include m.flex-center(column);
        gap: f.px2rem(16);
        grid-column: 1 / -1;
    }

    @include m.screen("sm") {
        &__container {
            padding-top: f.px2rem(384);
            padding-bottom: f.px2rem(32);
        }

        &__ctas {
            @include m.flex-center(row);
            gap: f.px2rem(16);
            justify-content: flex-start;
        }
    }

    @include m.screen("md") {
        &__container {
            .--richtext {
                grid-column: 1 / span 7;
            }
        }
    }

    @include m.screen("xl") {
        &__container {
            gap: f.px2rem(64);
            padding-top: f.px2rem(768);
            padding-bottom: f.px2rem(64);

            .--richtext {
                grid-column: 1 / span 6;
            }
        }
    }
}
