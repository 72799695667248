@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.feature-with-icon {
    &__container {
        &.--grid {
            grid-row-gap: f.px2rem(32);
        }
    }

    &__content {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: row;
        gap: f.px2rem(24);
    }

    &__image {
        max-height: f.px2rem(48);
        max-width: f.px2rem(48);
        min-height: f.px2rem(48);
        min-width: f.px2rem(48);
        border-radius: 50%;
        background-color: v.$white;
        @include m.flex-center;

        img {
            height: f.px2rem(24);
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: f.px2rem(8);
    }

    @include m.screen("sm") {
        &__container {
            &.--grid {
                grid-row-gap: f.px2rem(80);
            }
        }

        &__content {
            grid-column: span 6;
            flex-direction: column;
            gap: f.px2rem(48);
        }

        &__text {
            padding-right: f.px2rem(24);
            gap: f.px2rem(16);

            .link {
                margin-top: f.px2rem(16);
            }
        }
    }

    @include m.screen("md") {
        &__container {
            &.--grid {
                grid-row-gap: f.px2rem(64);
            }
        }
    }

    @include m.screen("lg") {
        &__container {
            &.--grid {
                display: flex;
            }
        }

        &__content {
            width: 100%;
        }
    }

    @include m.screen("xl") {
        &__content {
            gap: f.px2rem(64);
        }

        &__text {
            padding-right: f.px2rem(40);
            gap: f.px2rem(32);

            .link {
                margin-top: f.px2rem(32);
            }
        }

        &__image {
            max-height: f.px2rem(128);
            max-width: f.px2rem(128);
            min-height: f.px2rem(128);
            min-width: f.px2rem(128);
    
            img {
                height: f.px2rem(64);
            }
        }
    }
}