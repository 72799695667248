@use "website/assets/styles/abstracts/variables" as v;
@use "website/assets/styles/abstracts/mixins" as m;
@use "website/assets/styles/abstracts/functions" as f;

.faqs {
    &__container {
        .--border {
            border-top: 1px solid;
            grid-column: 1 / -1;
        }
    }

    &__text {
        grid-column: 1 / -1;
        padding-top: f.px2rem(32);
        padding-bottom: f.px2rem(32);
        display: flex;
        flex-direction: column;
        gap: f.px2rem(24);
    }

    &__accordion__container {
        grid-column: 1 / -1;

        .accordion__visible-part {
            .accordion {
                margin-top: 0.5rem;
            }
        }
    }

    &.t-bluish-teal {
        .accordion__visible-part {
            transition: all 0.3s ease-in-out;
        }

        .accordion__visible-part:hover,
        .accordion__visible-part:hover + .accordion__visible-part {
            border-color: v.$creamy-white !important;
        }
            
        .accordion__visible-part:hover {
            .toggle-arrow {
                path {
                    fill: v.$creamy-white;
                    fill-opacity: 1;
                }
            }
        }

        .toggle-arrow {
            &.active {
                path {
                    fill: v.$creamy-white;
                    fill-opacity: 1;
                }
            }
        }
    }

    &.t-creamy-white, &.t-white {
        .accordion__visible-part {
            transition: all 0.3s ease-in-out;
        }

        .accordion__visible-part:hover,
        .accordion__visible-part:hover + .accordion__visible-part {
            border-color: v.$dark-bluish-72 !important;
        }

        .accordion__visible-part:hover {
            .toggle-arrow {
                path {
                    fill: v.$dark-bluish;
                    fill-opacity: 1;
                }
            }
        }

        .toggle-arrow {
            &.active {
                path {
                    fill: v.$dark-bluish;
                    fill-opacity: 1;
                }
            }
        }
    }

    @include m.screen("sm") {
        &__text {
            padding-top: f.px2rem(96);
            padding-bottom: f.px2rem(64);
        }
    }

    @include m.screen("md") {
        &__text {
            grid-column: 1 / span 5;
        }

        &__accordion__container {
            grid-column: 7 / -1;
            padding-top: f.px2rem(96);
        }
    }

    @include m.screen("lg") {
        &__text {
            grid-column: 1 / span 4;
        }
    }

    @include m.screen("xl") {
        &__text {
            padding-top: f.px2rem(192);
            gap: f.px2rem(48);
            grid-column: 1 / span 4;
        }

        &__accordion__container {
            padding-top: f.px2rem(192);
        }
    }
}